import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import PageBase from '@/components/common/Page'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { layouts } from '@/utils/preset'
import Breadcrumb from '@/components/app/Breadcrumb.container'
import PageHead from '@/components/app/PageHead'
import ContactForm from '@/components/modules/Contact/Form'

const ContactIndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      const title = 'お問い合わせ'
      const description = '基幹システムを新たに導入・構築することは、企業にとっての新しい戦略の第1歩となります。現状分析から、将来の目的や目指すべきビジョン、そのために必要となるシステムのあるべき姿など、弊社も一緒に考えることが可能です。FVPで解決できることは多くあります。最大限にご活用いただけるよう、確かなご提案をさせてください。'
      return (
        <Root>
          <SEO location={props.location} title={title} description={description}/>
          <LD json={{
            '@type': 'WebPage',
            '@id': `${siteMeta.siteUrl}/contact/#webpage`,
            name: `${title} | ${siteMeta.title}`,
            description: description,
            url: `${siteMeta.siteUrl}/contact/`,
            inLanguage: 'ja',
            datePublished: '2019-11-10T10:00:00+09:00',
            dateModified: '2019-11-10T10:00:00+09:00',
            isPartOf: LDEntity.WebSite(siteMeta)
          }}/>
          <Breadcrumb path={props.location.pathname} />
          <Head>
            <PageHead
              title={title}
              description={description}
            />
          </Head>
          <Main>
            <ContactForm />
            <Border theme={props.theme} />
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div`
`

const Head = styled.div``

const Main = styled.div``

const Border = styled(layouts.components.FullWidth)`
  height: 1px;
  background-color: ${props => props.theme.line.base};
`

export default PageBase(ThemeConsumer(ContactIndexPage))

const query = graphql`
  query ContactIndexPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
        company {
          name
          logo
          siteUrl
        }
      }
    }
  }
`
