import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Headline from '@/components/app/Headline'
import Typography from '@/components/common/Typography'
import Form from '@/components/common/Form'

const ContactForm = props => {
  const { ...other } = props
  return (
    <StaticQuery
      query={query}
      render={data => {
        const form = data.forms.edges[0].node
        return (
          <Root {...other}>
            <Head>
              <Title theme={props.theme}>
                ご相談フォーム
              </Title>
            </Head>
            <Main>
              <Body variant="body1" theme={props.theme}>
                各種ご相談・お問い合わせ、ご意見・ご質問はこちらのフォームよりご送信ください。<br />
                ご相談は無料です。お気軽にご相談ください。<Link to="/faq/">よくある質問はこちらから。</Link>
              </Body>
              <Form {...form} css={styles.Form} beforeSubmit={beforeSubmit}/>
            </Main>
          </Root>
        )
      }}
    />
  )
}

const beforeSubmit = (values) => {
  window.dataLayer && window.dataLayer.push({
    event: 'app.conversion',
    eventCategory: 'お問い合わせ',
    eventAction: values.division,
    eventLabel: values.job
  })
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(tabletS)} {
    text-align: left;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
    br {
      display: none;
    }
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
  a {
    color: ${props => props.theme.primary.base};
  }
`

const styles = {
  Form: css`
    margin-top: 64px;
    @media ${mq.and(mobile)} {
      margin-top: 32px;
    }
    width: 100%;
  `
}

export default ThemeConsumer(ContactForm)

export const query = graphql`
  query ContactFormQuery {
    forms: allContactFormYaml {
      edges {
        node {
          name
          inputFields {
            name
            label
            required
            type
            value
          }
          action
          netlify
          endpoint
        }
      }
    }
  }
`
